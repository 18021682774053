import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';

import Search from 'components/search';
import HeaderToggleButton from './header-toggle-button';
import MenuHeaders from 'components/menu-headers';
import Logo from 'components/logo';
import Menu from 'components/menu';
import TabTrapper from 'components/tab-trapper';
import LoginLink from 'components/login-link';
import GlobalWarningBlock from 'components/global-warning-block';

const Header = ({
  globalWarningBlock,
  menuToggleLabel,
  searchToggleLabel,
  searchToggleText,
  linkToHomeUrl,
  linkToHomeText,
  menu,
  search,
  globalWarningBlockIsOpen,
  handleChangeWarningBlock
}) => {
  const [currentActiveGroup, setCurrentActiveGroup] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const headerRef = useRef(null);
  const timeoutRef = useRef(null);

  useClickOutside(headerRef, () => {
    setMenuIsOpen(false);
    setSearchIsOpen(false);
  });
  useEscape(() => {
    setMenuIsOpen(false);
    setSearchIsOpen(false);
  });

  const onSearchButtonClick = () => {
    setMenuIsOpen(false);
    setSearchIsOpen(prev => !prev);
  };

  const handleOnMenuToggleClick = () => {
    if (typeof document === 'undefined') return;
    setMenuIsOpen(prev => {
      if (prev) {
        document.body.style.overflow = 'auto';
      } else {
        document.body.style.overflow = 'hidden';
      }
      return !prev;
    });
    setSearchIsOpen(false);
  };

  const handleOnHeaderClick = targetHeader => {
    if (typeof document === 'undefined') return;
    document.body.style.overflow = 'auto';
    if (!targetHeader) return;

    searchIsOpen && setSearchIsOpen(false);

    if (currentActiveGroup === targetHeader) {
      setMenuIsOpen(false);
    }
    setCurrentActiveGroup(targetHeader);
    !menuIsOpen && setMenuIsOpen(true);
  };

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if (!menuIsOpen) {
      timeoutRef.current = setTimeout(() => setCurrentActiveGroup(''), 300);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    const resetMenu = () => {
      setMenuIsOpen(false);
      if (typeof document === 'undefined') return;
      document.body.style.overflow = 'auto';
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resetMenu);

      return () => {
        window.removeEventListener('resize', resetMenu);
      };
    }
  }, []);

  return (
    <header id="id-header-hotjar" className="header" ref={headerRef}>
      <div
        className={cn('header__background-shadow', {
          'header__background-shadow--active': menuIsOpen || searchIsOpen
        })}
      />
      {globalWarningBlock && (
        <GlobalWarningBlock
          {...globalWarningBlock}
          isOpen={globalWarningBlockIsOpen}
          handleIsOpen={handleChangeWarningBlock}
        />
      )}
      <div className="header__container">
        <div className="header__content">
          <div className="header__logo-container">
            <a
              aria-label={linkToHomeText}
              href={linkToHomeUrl}
              className={cn('header__logo', {
                'header__logo--search-active': searchIsOpen
              })}
            >
              <Logo theme={Logo.themes.menu} />
            </a>
          </div>
          <div className="header__menu-headers-container">
            <MenuHeaders
              headers={menu?.groups.map(group => group.title)}
              onClick={handleOnHeaderClick}
              activeHeader={currentActiveGroup}
            />
          </div>
          <div className="header__action-buttons">
            <HeaderToggleButton
              className="header__toggle-button-search"
              onClick={onSearchButtonClick}
              isActive={searchIsOpen}
              activeIconName="x"
              nonActiveIconName="search"
              label={searchToggleLabel}
              text={searchToggleText}
            />
            <HeaderToggleButton
              className="header__toggle-button-menu"
              onClick={handleOnMenuToggleClick}
              isActive={menuIsOpen}
              activeIconName="x"
              nonActiveIconName="hamburger"
              label={menuToggleLabel}
            />
            <div className="header__login-links">
              {menu?.loginLink && <LoginLink {...menu.loginLink} />}
            </div>
          </div>
        </div>
      </div>
      {search && (
        <div
          className={cn('header__search', {
            'header__search--open': searchIsOpen
          })}
        >
          <Collapse isOpen={searchIsOpen}>
            <div className="header__search-wrapper">
              <Search
                shouldFocusInput={true}
                searchIsOpen={searchIsOpen}
                theme={Search.themes.menu}
                {...search}
              />
            </div>
          </Collapse>
        </div>
      )}
      <div
        className={cn('header__menu', {
          'header__menu--open': menuIsOpen
        })}
      >
        <TabTrapper isActive={menuIsOpen}>
          <Collapse isOpen={menuIsOpen}>
            <Menu
              activeGroup={currentActiveGroup}
              handleCloseMenu={() => {
                setMenuIsOpen(false);
                if (typeof document !== 'undefined') {
                  document.body.style.overflow = 'auto';
                }
              }}
              {...menu}
            />
          </Collapse>
        </TabTrapper>
      </div>
    </header>
  );
};

Header.propTypes = {
  globalWarningBlock: PropTypes.exact(GlobalWarningBlock.propTypes),
  linkToHomeUrl: PropTypes.string,
  linkToHomeText: PropTypes.string,
  menuToggleLabel: PropTypes.string,
  searchToggleLabel: PropTypes.string,
  searchToggleText: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  menu: PropTypes.exact(Menu.propTypes),
  globalWarningBlockIsOpen: PropTypes.bool,
  handleChangeWarningBlock: PropTypes.func
};

Header.defaultProps = {
  linkToHomeUrl: '/',
  menuToggleLabel: 'Vis meny',
  searchToggleLabel: 'Vis søk',
  searchToggleText: 'Søk'
};

Header.propTypesMeta = {
  isOpen: 'exclude',
  globalWarningBlockIsOpen: 'exclude',
  handleChangeWarningBlock: 'exclude'
};

export default Header;
