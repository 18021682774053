/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import FilterContext from 'contexts/filter-context';

import CarouselButton from 'components/carousel-button';
import PromotedPerk from './promoted-perk';
import PropTypes from 'prop-types';
import Heading from 'components/heading';
import ContentWrapper from 'components/content-wrapper';

const swiperParams = {
  initialSlide: 0,
  spaceBetween: 24,
  height: 250,
  slidesPerView: 'auto',
  centeredSlides: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};

const PromotedPerkList = ({ items, title }) => {
  const { shouldShow } = React.useContext(FilterContext);

  const listHasItems = () => {
    return items.some(item => shouldShow(item.filterIds));
  };

  return listHasItems() ? (
    <ContentWrapper className="perk-list-page__perk-list perk-list-page__promoted-perk-list">
      <Heading
        level={2}
        theme={Heading.themes.h2}
        className="perk-list-page__heading"
      >
        {title}
      </Heading>
      <ul className="promoted-perk-list">
        {items.map(pageListItem => (
          <PromotedPerk
            key={`${pageListItem.url}${pageListItem.title}$`}
            isSlide={false}
            {...pageListItem}
          />
        ))}
      </ul>
      <div className="promoted-perk-slides">
        <Swiper
          modules={[Navigation]}
          containerClass="promoted-perk-slides"
          {...swiperParams}
        >
          {items.map(pageListItem => (
            <SwiperSlide key={`${pageListItem.url}${pageListItem.title}$`}>
              <PromotedPerk isSlide={true} {...pageListItem} />
            </SwiperSlide>
          ))}
          <div className="swiper-button-prev promoted-perk__navigation-button">
            <CarouselButton
              theme={CarouselButton.themes.previous}
              text="Forrige"
              className="carousel-button--full-width"
              isActive={true}
            />
          </div>
          <div className="swiper-button-next promoted-perk__navigation-button">
            <CarouselButton
              theme={CarouselButton.themes.next}
              text="Neste"
              isActive={true}
              className="carousel-button--full-width"
            />
          </div>
        </Swiper>
      </div>
    </ContentWrapper>
  ) : null;
};

PromotedPerkList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(PromotedPerk.propTypes)),
  missingItemsText: PropTypes.string
};

export default PromotedPerkList;
