import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from 'components/image';
import ContentWrapper from 'components/content-wrapper';
import InspoAnimatedTitle from 'components/inspo-animated-title';
import Heading from 'components/heading';

const themes = {
  dark: 'dark',
  light: 'light'
};

const HousingProjectHeader = ({
  image,
  mobileImage,
  logo1,
  logo2,
  logo3,
  logo4,
  title,
  theme
}) => {
  const [isTitleMounted, setIsTitleMounted] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setIsTitleMounted(true), 3500);
  }, []);
  return (
    <div
      className={cn('housing-project-header', {
        [`housing-project-header--${theme}`]: theme
      })}
    >
      {image && (
        <div className="housing-project-header__image-container">
          <div className="housing-project-header__image">
            <div className="housing-project-header__image-desktop">
              <Image theme={Image.themes.background} {...image}></Image>
            </div>
            <div className="housing-project-header__image-mobile">
              <Image theme={Image.themes.background} {...mobileImage}></Image>
            </div>
            <div className="housing-project-header__shadow"></div>
            <div className="housing-project-header__corner-shadow"></div>
            {title && (
              <div className="housing-project-header__title">
                <InspoAnimatedTitle
                  {...title}
                  isMounted={isTitleMounted}
                  theme="transparent"
                />
              </div>
            )}
            <ContentWrapper className="content-wrapper--in-housing-project-header">
              <div className="housing-project-header__logo-container">
                {logo1 && (
                  <Image className="housing-project-header__logo" {...logo1} />
                )}
                {logo2 && (
                  <Image className="housing-project-header__logo" {...logo2} />
                )}
                {logo3 && (
                  <Image className="housing-project-header__logo" {...logo3} />
                )}
                {logo4 && (
                  <Image className="housing-project-header__logo" {...logo4} />
                )}
              </div>
            </ContentWrapper>
          </div>
        </div>
      )}
      {title && (
        <div className="housing-project-header__title--mobile">
          <ContentWrapper>
            <Heading className="housing-project-header__title--mobile-text">
              {title.text}
            </Heading>
          </ContentWrapper>
        </div>
      )}
    </div>
  );
};

HousingProjectHeader.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  logo1: PropTypes.exact(Image.propTypes),
  logo2: PropTypes.exact(Image.propTypes),
  logo3: PropTypes.exact(Image.propTypes),
  logo4: PropTypes.exact(Image.propTypes),
  mobileImage: PropTypes.exact(Image.propTypes),
  title: PropTypes.exact(InspoAnimatedTitle.propTypes),
  theme: PropTypes.oneOf(Object.keys(themes))
};

HousingProjectHeader.propTypesMeta = {
  theme: 'exclude'
};

export default HousingProjectHeader;
